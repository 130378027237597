import React from "react";
import Layout from '../../components/Layout'

class NetworkIndexPage extends React.Component {
    render() {
        const copy = "This is some copy";
        return (
            <Layout>
                <div>
                    <h1>Network Home</h1>
                    <p>Ain't that cool?</p>
                    <p>{copy}</p>
                </div>
            </Layout>
        )
    }
}

export default NetworkIndexPage